"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var regexISO = /^(19[8-9]\d|20\d{2})[-](0[1-9]|1[0-2])[-](0[1-9]|[12]\d|3[01])[T](0\d|1\d|2[0-4])[:]([0-5]\d)[:]([0-5]\d)[.](\d{3})[Z]$/;
var regexDate = /^(19[8-9]\d|20\d{2})[-](0[1-9]|1[0-2])[-](0[1-9]|[12]\d|3[01])/;
var isDate = function (value) {
    var stringValue = value.toString();
    var isValidDate = !Number.isNaN(new Date(stringValue).getDate());
    var isValidDateString = isValidDate && stringValue.match(regexDate) !== null;
    var isFormatValid = isValidDateString && new Date(stringValue).toISOString().match(regexISO) !== null;
    var isAlreadyDate = value instanceof Date;
    return isFormatValid || isAlreadyDate;
};
exports.default = isDate;
