"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toTitleCase = function (title) {
    var words = title.toLowerCase().split(' ');
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
};
exports.default = toTitleCase;
