"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var truncateText = function (complement, text, length) {
    var result = text;
    if (text && text.length > length) {
        result = text.substr(0, length);
        var indexLastSpace = result.lastIndexOf(' ');
        if (indexLastSpace > -1 && typeof complement === 'string') {
            return "".concat(result.substr(0, indexLastSpace).trimEnd()).concat(complement);
        }
        if (indexLastSpace > -1 && typeof complement === 'object') {
            result = result.substr(0, indexLastSpace).trimEnd();
        }
        else {
            return "".concat(result).concat(complement);
        }
    }
    return typeof complement === 'object' ? "".concat(complement[0]).concat(result).concat(complement[2]) : result;
};
exports.default = truncateText;
