"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var objectDifference = function (obj1, obj2) {
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
        return obj1;
    }
    var diffs = {};
    var compare = function (item1, item2, prop) {
        if (item1 !== item2) {
            diffs[prop] = { prev: item1, new: item2, type: prop };
        }
    };
    Object.keys(obj1).forEach(function (prop) { return compare(obj1[prop], obj2[prop], prop); });
    return diffs;
};
exports.default = objectDifference;
