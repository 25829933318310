"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toLocalTime = function (date) {
    if (typeof date === 'string' && date.toUpperCase().endsWith('Z')) {
        return new Date(date);
    }
    var baseDate = date instanceof Date ? date : new Date(date);
    return new Date(Date.UTC(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), baseDate.getHours(), baseDate.getMinutes(), baseDate.getSeconds(), baseDate.getMilliseconds()));
};
exports.default = toLocalTime;
